import { graphql, useStaticQuery } from 'gatsby'

export const useSiteManifestoI18N_EN = () => {
  const data: any = useStaticQuery(graphql`
    query {
      strapiManifest {
        manifestTitle
        manifestDescription {
          data {
            manifestDescription
          }
        }
      }
    }
  `)

  return data.strapiManifest;

}