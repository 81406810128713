import * as React from 'react';
import { useEffect, useState } from 'react';
import DefaultAppView from '../app-view/default-app-view';
import { SEO } from '../components/seo';
import { StaticQueryDocument, graphql, useStaticQuery } from 'gatsby';
import { SEOData } from '../constants/types';
import { getUserPreferredLocale } from '../functions/utils';
import { useSiteManifestoI18N_IT } from "../hooks/site-strapi-i18n-IT/site-manifesto-i18n-IT";
import { useSiteManifestoI18N_EN } from "../hooks/site-strapi-i18n-EN/site-manifesto-i18n-EN";

// MANIFESTO
const Manifesto = () => {

  const _userLocale: string = getUserPreferredLocale();

  // --- Getting datas w/ querys from strapi 
  const strapiI18NContent: any | undefined = (_userLocale.includes('it') || _userLocale.includes('IT')) ? useSiteManifestoI18N_IT() : useSiteManifestoI18N_EN();

  const manifestTitle: string = strapiI18NContent && strapiI18NContent?.manifestTitle;
  const manifestDescription: string = strapiI18NContent && (_userLocale.includes('it') || _userLocale.includes('IT')) ? strapiI18NContent.manifestDescription : strapiI18NContent?.manifestDescription.data.manifestDescription;
  // ---

  // --- STATES
  const [showText, setShowText] = useState<boolean>(false);
  // ---

  // Hook w/ timeout for manifesto animation
  useEffect(() => {

    setTimeout(() => {
      setShowText(true);
    }, 3000)

  },[])
  // ---

  return (
    <DefaultAppView currentUrl='/manifesto' userLocale={_userLocale}>
        <div className='relative flex items-center justify-center w-full min-h-screen px-5 bg-black lg:px-36 text-cardinal-white'>
          <div className='flex uppercase text-6xl lg:text-[224px] text-center opacity-[0.01] leading-none manifestoFadeIn'>
            <span>{ manifestTitle }</span>
          </div>
          <div className={`absolute flex flex-wrap items-center text-xl md:text-4xl space-x-2 justify-center text-center w-5/6 px-5 lg:px-36 lg:text-3xl 2xl:text-5xl leading-tight lg:leading-none pt-14 ${showText ? 'manifestoFadeUp' : 'opacity-0'}`}>
            {/* --- Manifesto enlighten word of text animation --- */}
            {   manifestDescription && 
                manifestDescription.split(' ').map((word: string, index: number) => {
                  let letterStyle = {"animationDelay": (4 + index / 4) + "s"};
                  return (     
                    <span className='lg:pr-3 text-cardinal-white enlightLetter' style={letterStyle} key={index}>{word}</span>
                  )
                })
            }
            {/* --- */}
          </div>
        </div>
    </DefaultAppView>
  )

}

export default Manifesto;

// ----------------------- SEO CREATION ---------------------- //

export const seoData: StaticQueryDocument | undefined = graphql`
  query {
    strapiManifest {
      SEO {
        metaTitle
        metaDescription
        keywords
        preventIndexing
        pathname
      }
    }
  }
`

export const Head = () => {
  const seo: SEOData = seoData && useStaticQuery(seoData).strapiManifest.SEO; 

  return (
    <SEO 
      title={seo.metaTitle} 
      description={seo.metaDescription} 
      keywords={seo.keywords} 
      preventIndexing={seo.preventIndexing} 
      pathname={seo.pathname}/>   
  )
}
