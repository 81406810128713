import { graphql, useStaticQuery } from 'gatsby';

export const useSiteManifestoI18N_IT = () => {
  const data: any = useStaticQuery(graphql`
    query {
      strapiManifest {
        localizations {
          data {
            attributes {
              manifestTitle
              manifestDescription
            }
          }
        }
      }
    }  
  `)

  return data.strapiManifest.localizations.data[0].attributes;

}